import React from 'react';

interface Link {
  href: string;
  text: string;
}

interface Props {
  title?: string;
  links: Link[];
}

const ArticleComponent: React.FC<Props> = ({ title, links }) => {
  return (
    <div className="content-secondary">
      <h3 className="mb-3">{title || 'En este artículo encontrarás estos temas:'}</h3>
      <ul className="list-links">
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.href} className="text-icon">
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ArticleComponent;
