import React, { useState, useEffect, useMemo } from 'react';
import SkeletonLoaderComponent from '../../components/Loader/Skeleton/skeleton.component';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import SearchBarComponent from '../../components/SearchBar/searchBar.component';
import CarouselComponent from '../../components/Carousel/carousel.component';
import CardImageComponent from '../../components/Cards/cardImage.component';
import CardTextComponent from '../../components/Cards/cardText.component';
import VideoPlayer from '../../components/VideoPlayer/videoPlayer.component';
import ContactLinksComponent from '../../components/ContactLinks/contactLinks.component';
import useLoadData from '../../hooks/useLoadData';
import {
  Carousel,
  ContactBanner,
  Home,
  Modules,
  strapiDoc,
} from '../../@types/strapiDocs';
import { useSearchParams } from 'react-router-dom';
import { fetchStrapiDocs } from '../../api/strapi';

const HomeScreen: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const [clientRolsIds, setClientRolsIds] = useState<string[]>([]);

  const getDefaultRol = async () => {
    try {
      const query = {
        filters: { Name: { $eq: 'General' } },
      };
      const data = await fetchStrapiDocs('/rols', query);
      const strapiRol = data.data[0];
      if (strapiRol) {
        const rolId = strapiRol.attributes.rolId || '';
        localStorage.setItem('clientRolsIds', JSON.stringify([rolId]));
        setClientRolsIds([rolId]);
      }
      return strapiRol;
    } catch (error) {
      console.error('Error fetching user from Strapi:', error);
    }
  };

  const fetchClientFromStrapi = async (clientId: string) => {
    try {
      const query = {
        populate: '*',
        filters: {
          clientId: {
            $eq: clientId,
          },
        },
      };
      const data = await fetchStrapiDocs('/clients', query);
      const strapiClient = data.data[0];
      if (strapiClient) {
        const clientRols = strapiClient.attributes.rols?.data || [];
        const clientRolsIds = clientRols.map(
          (rol) => rol.attributes.rolId || ''
        );
        localStorage.setItem('clientRolsIds', JSON.stringify(clientRolsIds));
        setClientRolsIds(clientRolsIds);
      } else {
        await getDefaultRol();
      }
      return strapiClient;
    } catch (error) {
      console.error('Error fetching user from Strapi:', error);
      await getDefaultRol();
      return null;
    }
  };

  useEffect(() => {
    const clientId = searchParams.get('clientId');
    if (clientId) {
      console.log('Client ID:', clientId);
      fetchClientFromStrapi(clientId);
      setSearchParams({ clientId });
    } else {
      getDefaultRol();
    }
  }, [searchParams, setSearchParams]);

  const { data } = useLoadData<Home>('/home', {
    populate: {
      SearchContent: true,
      HeroCarousel: {
        populate: {
          Image: true,
        },
      },
      ContentNews: {
        populate: {
          Image: true,
          Icon: true,
        },
      },
      TitleModule: true,
      Modules: {
        populate: {
          Icon: true,
        },
      },
      TitleVideo: true,
      Video: true,
      TitleAdmin: true,
      ModulesAdmin: {
        populate: {
          Icon: true,
        },
      },
      TitleResources: true,
      ContentResources: {
        populate: {
          Image: true,
          Icon: true,
        },
      },
    },
  });
  console.log('Home', data);

  // Cargar datos de `api::general.general`
  const { data: bannerData } = useLoadData<ContactBanner>('/contact-banner', {
    populate: {
      Options: {
        populate: {
          Icon: true,
        },
      },
    },
  });

  // Hero
  const heroTitle = data?.attributes.Title || '';
  const heroSubtitle = data?.attributes.Subtitle || '';
  const heroDescription = data?.attributes.Description || '';

  const options = useMemo(() => {
    if (clientRolsIds && clientRolsIds.length > 0) {
      return {
        populate: '*',
        filters: { rols: { rolId: { $in: clientRolsIds } } },
      };
    }
    return {
      populate: '*',
    };
  }, [clientRolsIds]);

  // Cambiamos el endpoint a `/modules` para cargar solo los módulos
  const { data: modulesData } = useLoadData<strapiDoc[]>('/modules', options);

  // Search
  const searchOptions =
    modulesData?.map((module) => ({
      value:
        module.attributes?.Slug ||
        module.attributes?.Title?.toLowerCase() ||
        'untitled',
      label: module.attributes?.SuperTitle || 'Untitled',
    })) || [];

  console.log('Search', searchOptions);

  const modulesOptions =
    data?.attributes?.SearchContent?.data.map(
      (item: {
        id: number;
        attributes: {
          Slug?: string | null;
          Title: string;
        };
      }) => ({
        value: item.attributes.Slug || item.attributes.Title.toLowerCase(),
        label: item.attributes.Title,
      })
    ) || [];

  // Carousel
  const carouselItems =
    data?.attributes?.HeroCarousel?.map((item: Carousel) => ({
      image: item.Image?.data?.attributes.url || '',
      url: item.Url || '',
      externalLink: item.ExternalLink || false,
    })) || [];

  // News
  const newsCards =
    data?.attributes?.ContentNews?.data.map((item) => ({
      image: item.attributes.Image?.data?.attributes.url || '',
      icon: item.attributes.Icon?.data?.attributes.url || '',
      url: item.attributes.Slug || '',
      title: item.attributes.Title || '',
      description: item.attributes.Description || '',
    })) || [];

  // Title module
  const titleModule = data?.attributes?.TitleModule.Title;
  const descriptionModule = data?.attributes?.TitleModule.Description;

  // Modules
  const moduleItems =
    data?.attributes?.Modules?.data.map((item: Modules) => ({
      icon: item.attributes.Icon?.data?.attributes.url || '',
      description: item.attributes.Description || '',
      title: item.attributes.Title || '',
      url: item.attributes.Slug || '',
    })) || [];

  // Title video
  const titleVideo = data?.attributes?.TitleVideo.Title || '';
  const descriptionVideo = data?.attributes?.TitleVideo.Description || '';

  // Video
  const videoUrl = data?.attributes?.Video?.Url || '';

  // Title admin
  const titleAdmin = data?.attributes?.TitleAdmin.Title || '';
  const descriptionAdmin = data?.attributes?.TitleAdmin.Description || '';

  // Modules admin
  const moduleAdminItems =
    data?.attributes?.ModulesAdmin?.data.map((item: Modules) => ({
      icon: item.attributes.Icon?.data?.attributes.url || '',
      description: item.attributes.Description || '',
      title: item.attributes.Title || '',
      url: item.attributes.Slug || '',
    })) || [];

  // Title resources
  const titleResources = data?.attributes?.TitleResources.Title || '';
  const descriptionResources =
    data?.attributes?.TitleResources.Description || '';

  // Resources
  const resourcesCards =
    data?.attributes?.ContentResources?.data.map((item) => ({
      image: item.attributes.Image?.data?.attributes.url || '',
      icon: item.attributes.Icon?.data?.attributes.url || '',
      url: item.attributes.Slug || '',
      title: item.attributes.Title || '',
      description: item.attributes.Description || '',
    })) || [];

  //***** Banner */
  const mapTypeToIconType = (
    type: string
  ): 'support' | 'whatsapp' | 'email' | 'phone' => {
    switch (
      type.toLowerCase() // Convertir a minúsculas para evitar problemas de capitalización
    ) {
      case 'support':
        return 'support';
      case 'whatsapp':
        return 'whatsapp';
      case 'email':
        return 'email';
      case 'phone':
        return 'phone';
      default:
        console.error(`Unknown type: ${type}`);
        return 'support'; // Valor por defecto en caso de error
    }
  };

  const bannerTitle = bannerData?.attributes?.Title || '';
  const bannerDescription = bannerData?.attributes?.Description || '';
  const bannerLinks =
    bannerData?.attributes?.Options?.filter((item) => item.Type) // Filtrar elementos sin `type`
      ?.map((item) => ({
        name: item.Name || '',
        type: mapTypeToIconType(item.Type!), // Type assertion as non-null
        url: item.Url || '',
        external: item.ExternalLink || '',
      })) || [];

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      <HeaderComponent />
      <section className="hero-image">
        <div className="container">
          <div className="center-row row">
            <div className="col-xl-6 col-12">
              <section className="hero-image__text">
                {loading ? (
                  <SkeletonLoaderComponent darkTheme={true} />
                ) : (
                  <>
                    {heroSubtitle && (
                      <div
                        className="text-white h3 mb-0 text-500"
                        dangerouslySetInnerHTML={{
                          __html: heroSubtitle,
                        }}
                      />
                    )}

                    {heroTitle && (
                      <div
                        className="text-white"
                        dangerouslySetInnerHTML={{
                          __html: heroTitle,
                        }}
                      />
                    )}
                    <hr />
                  </>
                )}
                {heroDescription && (
                  <article className="text-blue300 text-21">
                    {loading ? (
                      <SkeletonLoaderComponent darkTheme={true} />
                    ) : (
                      <>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: heroDescription,
                          }}
                        />
                      </>
                    )}
                  </article>
                )}

                <SearchBarComponent
                  options={searchOptions}
                  itemsModules={modulesOptions}
                />
              </section>
            </div>
            <aside className="col-xl-6 col-12">
              <CarouselComponent items={carouselItems} />
            </aside>
          </div>
        </div>
      </section>
      {newsCards && (
        <section className="module">
          <div className="container">
            <div className="row justify-content-center">
              {newsCards.map((news, index) => (
                <div key={index} className="col-lg-5 mb-4 col-12">
                  <CardImageComponent
                    imageUrl={news.image}
                    title={news.title}
                    text={news.description}
                    url={`/contenidos/${news.url}`}
                    icon={news.icon}
                  />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}

      <section className="module module--color1 cards-box">
        <div className="container">
          <div className="row">
            {(titleModule || descriptionModule) && (
              <div className="col-12 mb-3">
                {titleModule && (
                  <div
                    className="h3 text-500 text-blue700 text-left-line "
                    dangerouslySetInnerHTML={{
                      __html: titleModule,
                    }}
                  />
                )}
                {descriptionModule && (
                  <div
                    className="text-gray600"
                    dangerouslySetInnerHTML={{
                      __html: descriptionModule,
                    }}
                  />
                )}
              </div>
            )}
            {moduleItems.map((module, index) => (
              <div className="col-lg-3 col-md-4 col-12 mb-4" key={index}>
                <CardTextComponent
                  title={module.title}
                  text={module.description}
                  icon={module.icon}
                  url={module.url}
                />
              </div>
            ))}

            <div className="col-12 d-flex justify-content-center cards-box__bottom">
              <a href="/modulos-sitic" className="btn btn--simple-icon">
                Ir a Todos los módulos{' '}
                <i className="icon icon--arrow-right"></i>
              </a>
            </div>
          </div>
        </div>
      </section>
      {(titleVideo || descriptionVideo || videoUrl) && (
        <section className="module module--color2">
          <div className="container">
            <div className="center-row row">
              {(titleVideo || descriptionVideo) && (
                <div className="col-lg-5 col-12">
                  {titleVideo && (
                    <div
                      className="h3 text-500 text-white text-left-line"
                      dangerouslySetInnerHTML={{ __html: titleVideo }}
                    />
                  )}

                  {descriptionVideo && (
                    <div
                      className="text-blue300"
                      dangerouslySetInnerHTML={{ __html: descriptionVideo }}
                    />
                  )}
                </div>
              )}
              {videoUrl && (
                <aside className="col-lg-6 col-12 offset-lg-1">
                  <VideoPlayer videoUrl={videoUrl} />
                </aside>
              )}
            </div>
          </div>
        </section>
      )}

      <section className="module module--color1 cards-box">
        <div className="container">
          <div className="row">
            {(titleAdmin || descriptionAdmin) && (
              <div className="col-12 mb-3">
                {titleAdmin && (
                  <div
                    className="h3 text-500 text-blue700 text-left-line"
                    dangerouslySetInnerHTML={{ __html: titleAdmin }}
                  />
                )}
                {descriptionAdmin && (
                  <div
                    className="text-gray600 col-lg-5 col-12"
                    dangerouslySetInnerHTML={{ __html: descriptionAdmin }}
                  />
                )}
              </div>
            )}

            {moduleAdminItems.map((module, index) => (
              <div className="col-lg-3 col-md-4 col-12 mb-4" key={index}>
                <CardTextComponent
                  title={module.title}
                  text={module.description}
                  icon={module.icon}
                  url={module.url}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <section className="module">
        <div className="container">
          <div className="row">
            {(titleResources || descriptionResources) && (
              <div className="col-12 mb-3">
                {titleResources && (
                  <div
                    className="h3 text-500 text-blue700 text-left-line"
                    dangerouslySetInnerHTML={{ __html: titleResources }}
                  />
                )}
                {descriptionResources && (
                  <div
                    className="text-gray600 col-lg-5 col-12"
                    dangerouslySetInnerHTML={{ __html: descriptionResources }}
                  />
                )}
              </div>
            )}

            {resourcesCards.map((news, index) => (
              <div key={index} className="col-lg-4 mb-4 col-12">
                <CardImageComponent
                  imageUrl={news.image}
                  title={news.title}
                  text={news.description}
                  url={`/contenidos/${news.url}`}
                  icon={news.icon}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
      <ContactLinksComponent
        title={bannerTitle}
        description={bannerDescription}
        links={bannerLinks}
      />
      <FooterComponent />
    </>
  );
};

export default HomeScreen;
