import React from 'react';
import ContentLoader from "react-content-loader"


type Props = {
  darkTheme?: boolean;
};

const SkeletonLoaderImageComponent: React.FC<Props> = ({darkTheme = false}) => {

  return (
    <>
      <ContentLoader 
        speed={2}
        width={'100%'}
        height={'100%'}
        viewBox="0 0 500 200"
        backgroundColor={darkTheme ? "#4B90B9" : "#f3f3f3"}
        foregroundColor={darkTheme ? "#275EA7" : "#ecebeb"}
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="100%" /> 
      </ContentLoader>
    </>
  );
};

export default SkeletonLoaderImageComponent;