import React from 'react';

interface Props {
  type?: string;
  url?: string;
  title?: string;
  description?: string;
}

const ListComponent: React.FC<Props> = ({type, url, title, description}) => {
  

  const truncatedDescription = description ? truncateDescription(description) : '';

  return (
    <div className="list-element">
       <>
          {type && <p className="text-icon text-12 text-blue300 mb-0"><i className="icon icon--document-small"></i> {type}</p>}
          {url && <h4 className="text-blue700 text-400 mb-1 h6"><a href={url}>{title}</a></h4>}
          {description && <article className="list-element__description"  dangerouslySetInnerHTML={{__html: truncatedDescription}}/>}
        </>
      
    </div>
  );
};

const truncateDescription = (html: string): string => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  const paragraphs = tempDiv.querySelectorAll('p');

  if (paragraphs.length > 2) {
    let truncatedHTML = '';
    for (let i = 0; i < 2; i++) {
      truncatedHTML += paragraphs[i].outerHTML;
    }
    truncatedHTML += '...';
    return truncatedHTML;
  } else if (paragraphs.length > 0) {
    return html; // Si hay 2 párrafos o menos, devuelve el HTML original
  } else {
    // Si no hay párrafos, recortar el texto sin párrafos
    const maxLength = 200; // Número máximo de caracteres antes de truncar
    if (html.length > maxLength) {
      return html.slice(0, maxLength) + '...';
    }
    return html; // Si el contenido es corto, devolver el HTML original
  }
};


export default ListComponent;