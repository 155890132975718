import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import { Menu, MenuItem, Link } from '../@types/strapiDocs';
import useLoadData from '../hooks/useLoadData';

interface Props {
  position?: string;
  theme?: string;
}

const HeaderComponent: React.FC<Props> = ({ position, theme }) => {
  const { data } = useLoadData<Menu>('/menu', {
    populate: {
      Logo: true,
      LogoColor: true,
      MenuItem: {
        populate: {
          SubItem: true,
        },
      },
    },
  });


  // Menu options
  const menuName = data?.attributes?.Name || '';
  const menuLogo = data?.attributes?.Logo?.data?.attributes?.url || '';
  const menuLogoColor = data?.attributes?.LogoColor?.data?.attributes?.url || '';
  const menuItems = data?.attributes?.MenuItem || [];

  return (
    <header className={`${position === 'relative' ? 'header--relative' : ''} ${theme === 'light' ? 'header--light' : ''}`}>
      <Navbar expand="lg" className="header__inner">
        <Container>
          <Navbar.Brand href="/">
            {(menuLogoColor || menuLogo) && (
              <img src={theme === 'light' ? menuLogoColor : menuLogo} alt={menuName} />
            )}

            {menuName && (
              <span className={`${theme === 'light' ? 'text-blue700' : 'text-white'} text-uppercase text-16`}>
                {menuName}
              </span>
            )}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {menuItems.map((menuItem: MenuItem) => (
                menuItem.SubItem && menuItem.SubItem.length > 0 ? (
                  <NavDropdown key={menuItem.id} title={menuItem.Name}>
                    {menuItem.SubItem.map((subItem: Link) => (
                      <NavDropdown.Item key={subItem.id} href={subItem.Url || '#'}>
                        {subItem.Name}
                      </NavDropdown.Item>
                    ))}
                  </NavDropdown>
                ) : (
                  <Nav.Link key={menuItem.id} href={menuItem.Url || '#'} target={menuItem.ExternalLink ? '_blank' : '_self'} rel="noreferrer">
                    {menuItem.Name}
                  </Nav.Link>
                )
              ))}
              <Nav.Link href="/inicio-de-sesion" className="nav__profile">
                <i className={`${theme === 'light' ? 'icon--profile-color' : 'icon--profile'} icon`}></i>
                <span>Mi perfil</span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default HeaderComponent;
