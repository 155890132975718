import React, { useState, useEffect } from 'react';
import SkeletonLoaderComponent from '../Loader/Skeleton/skeleton.component';

interface Props {
  title?: string;
  url?: string;
  theme?: string;
  font?: string;
  size?: string;
  description?: string;
  imageUrl?: string;
}

const HeroSmallComponent: React.FC<Props> = ({title, url, theme, font, size, description, imageUrl}) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <section className={`${theme === 'light' ? 'hero--light' : theme === 'color' ? 'hero--color' : ''} hero-small`}>
      <div className="container">
        <div className="row">
          <div className={size == 'large' ? 'col-12' : 'col-lg-6 col-12'}>
            <section className="hero-image__text">
              {url && <div className="breadcrumbs">
                <a href={url} className={` text-icon ${(theme == 'light' || theme == 'color') ? "text-blue700" : "text-white"}`}><i className={`icon ${(theme == 'light' || theme == 'color') ? "icon--home-color" : "icon--home"}`}></i> Inicio</a>
              </div>}
              
              {title && loading ? (
                <SkeletonLoaderComponent darkTheme={true}/>
              ) : (
                <>
                  <h1 className={`${font == 'medium' ? "h3 text-500" : ''} ${(theme == 'light' || theme == 'color') ? "text-blue700" : "text-white"}`}>{title}</h1>
                  <hr  className={theme == 'light' ? 'hr--light' : ''}/>
                </>
              )}
              {description && <div className="hero-small__descrtiption text-18" dangerouslySetInnerHTML={{__html: description}}/>
               }
              
              </section>
          </div>
          {imageUrl && <aside className="hero__aside col-lg-5 col-12">
            <figure>
                <img src={imageUrl} alt={title}/>
            </figure>
          </aside>}
          
        </div>
      </div>
    </section>
  );
};

export default HeroSmallComponent;