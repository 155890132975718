import React from 'react';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import { Page } from '../../@types/strapiDocs';
import useLoadData from '../../hooks/useLoadData';
import SkeletonBodyLoaderComponent from '../../components/Loader/Skeleton/skeletonBody.component';

const PrivacyScreen: React.FC = () => {
  const { data, loading, error } = useLoadData<Page>('/aviso-de-privacidad', {
    populate: '*'
  });
  console.log('PAGE', data);
  console.log('ERROR', error);

  if (loading) return <div className="container-fluid pt-3"><SkeletonBodyLoaderComponent /></div>;
  if (error) return <div className="badge bg-danger">Error loading data: {error}</div>;

  // Content
  const title = data?.attributes?.Title || '';
  const description = data?.attributes?.Description || '';

  return (
    <>
      <HeaderComponent theme="light" />
      <section className="page">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {title && <h1 className="h2">{title}</h1>}
              <article dangerouslySetInnerHTML={{ __html: description }} />
            </div>
          </div>
        </div>
      </section>    
      <FooterComponent />
    </>
  );
};

export default PrivacyScreen;
