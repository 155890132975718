import React, { useState } from 'react';

interface Props {
  collection?: string;
  module?: string;
  username?: string;
  onReviewSubmit: (review: string) => void;
  isReviewSubmitted: boolean;
}

const LikeComponent: React.FC<Props> = ({ collection, module, username, onReviewSubmit, isReviewSubmitted }) => {
  
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const handleButtonClick = (buttonType: string) => {
    if (!isReviewSubmitted) {
      const review = buttonType === 'like' ? 'Review positivo' : 'Review negativo';
      setActiveButton(buttonType);
      onReviewSubmit(review);
    }
  };

  console.log(collection, module, username)

  return (
    <div className="module module--color3 liker-module">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="liker">
              <p className="text-white text-21 mb-0"><strong>¿Qué opinas del contenido de este artículo?</strong></p>
              <div className="liker__button">
                <button
                  type="button"
                  className={`liker__button-btn ${activeButton === 'like' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('like')}
                  disabled={isReviewSubmitted}
                >
                  <i className="icon icon--like"></i>
                </button>
                <button
                  type="button"
                  className={`liker__button-btn ${activeButton === 'dislike' ? 'active' : ''}`}
                  onClick={() => handleButtonClick('dislike')}
                  disabled={isReviewSubmitted}
                >
                  <i className="icon icon--dislike"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LikeComponent;
