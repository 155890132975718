import React from 'react';
import HeaderComponent from '../../../Shared/header.shared';
import FooterComponent from '../../../Shared/footer.shared';

const LoginScreen: React.FC = () => {
  return (
    <>
      <HeaderComponent theme="light" />
      <section className="page">
        <div className="module">
          <div className="container">
            <div className="row">
              <div className="col-md-5 col-12 mx-auto">
                <h1 className="h2 text-center mb-3">Ingresar a Centro de Ayuda</h1>
                <form className="form-box">
                  <div className="form-row">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" required className="form-control" placeholder='micorreo@dominio.com'/>
                  </div>
                  <div  className="form-row">
                    <label htmlFor="password">Contraseña</label>
                    <input type="password" id="password" name="password" required className="form-control" placeholder='********'/>
                  </div>
                  <div className="form-row d-flex justify-content-center">
                    <button type="submit" className="btn btn--type1">Iniciar sesión</button>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
       
      </section>
      <FooterComponent />
    </>
  );
};

export default LoginScreen;