import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import swal from 'sweetalert2';

interface ModalShareProps {
  show: boolean;
  handleClose: () => void;
  shareUrl?: string; // URL que se compartirá
}

const ModalShareComponent: React.FC<ModalShareProps> = ({ show, handleClose, shareUrl }) => {
  const [email, setEmail] = useState('');
  const [subject, setSubject] = useState('');
  const [comment, setComment] = useState('');
  const [isEmailFocused, setIsEmailFocused] = useState(false);
  const [isSubjectFocused, setIsSubjectFocused] = useState(false);
  const [isCommentFocused, setIsCommentFocused] = useState(false);

  const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setter(event.target.value);
  };

  const handleFocus = (setter: React.Dispatch<React.SetStateAction<boolean>>) => () => {
    setter(true);
  };

  const handleBlur = (setter: React.Dispatch<React.SetStateAction<boolean>>, value: string) => () => {
    setter(value !== '');
  };

  const isFormValid = email !== '' && subject !== '' && comment !== '';

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
   
    try {
      const response = await fetch('/send-email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          subject,
          comment,
          url: shareUrl, // Añadir la URL a los datos enviados
        }),
      });

      if (!response.ok) {
        throw new Error('Error al enviar el correo');
      }

      setEmail('');
      setSubject('');
      setComment('');
      setIsEmailFocused(false);
      setIsSubjectFocused(false);
      setIsCommentFocused(false);

      handleClose();
      swal.fire({
        title: 'Correo enviado',
        text: 'Tu correo ha sido enviado con éxito',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      console.error('Error:', error);
      swal.fire({
        title: 'Error',
        text: 'Hubo un problema al enviar el correo',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Compartir por correo
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div className="form-row from--control-wrapp">
            <input
              type="email"
              className="form-control"
              placeholder={isEmailFocused || email !== '' ? '' : 'Correo electrónico'}
              value={email}
              onChange={handleInputChange(setEmail)}
              onFocus={handleFocus(setIsEmailFocused)}
              onBlur={handleBlur(setIsEmailFocused, email)}
            />
            <label>Correo electrónico</label>
          </div>
          <div className="form-row from--control-wrapp">
            <input
              type="text"
              className="form-control"
              placeholder={isSubjectFocused || subject !== '' ? '' : 'Asunto'}
              value={subject}
              onChange={handleInputChange(setSubject)}
              onFocus={handleFocus(setIsSubjectFocused)}
              onBlur={handleBlur(setIsSubjectFocused, subject)}
            />
            <label>Asunto</label>
          </div>
          <div className="form-row from--control-wrapp">
            <textarea
              className="form-control"
              placeholder={isCommentFocused || comment !== '' ? '' : 'Comentario'}
              value={comment}
              onChange={handleInputChange(setComment)}
              onFocus={handleFocus(setIsCommentFocused)}
              onBlur={handleBlur(setIsCommentFocused, comment)}
            />
            <label>Comentario</label>
          </div>
          <div className="form-row row--last d-flex justify-content-center">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!isFormValid}
            >
              Enviar <i className="icon icon--arrow-circle"></i>
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalShareComponent;
