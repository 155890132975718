import React from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';

interface Module {
  id: number;
  name: string;
}

interface Props {
  namePlaceholder?: string;
  modules: Module[]; // Nueva prop para recibir módulos desde el padre
  onSelectModule: (item: Module) => void; // Callback para manejar la selección en el padre
  onSearchChange: (searchTerm: string) => void; // Callback para manejar el término de búsqueda en el padre
}

const SearchBarSimpleComponent: React.FC<Props> = ({ namePlaceholder, modules, onSelectModule, onSearchChange }) => {
  
  const handleOnSelect = (item: Module) => {
    // Llamamos al callback del padre para manejar la selección del módulo
    onSelectModule(item);
  };

  const handleOnSearch = (string: string) => {
    // Llamamos al callback del padre para manejar el término de búsqueda
    onSearchChange(string);
  };

  const formatResult = (item: Module) => {
    return <span>{item.name}</span>;
  };

  return (
    <div className="search-bar bar--simple">
      <ReactSearchAutocomplete
        items={modules}
        onSearch={handleOnSearch} // Aquí llamamos a handleOnSearch
        onSelect={handleOnSelect}
        autoFocus
        formatResult={formatResult}
        placeholder={namePlaceholder}
        styling={{ zIndex: 3 }} // Asegúrate de que el autocompletado se muestre correctamente
      />
      <button type='submit' className="btn"><span className="material-symbols-outlined">search</span></button>
    </div>
  );
};

export default SearchBarSimpleComponent;
