import React, { useState, useEffect } from 'react';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import HeroSmallComponent from '../../components/HeroImage/heroSmall.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchBarSimpleComponent from '../../components/SearchBar/searchBarSimple.component';
import CardTextComponent from '../../components/Cards/cardText.component';
import Paginator from '../../components/Paginator/paginator.component';
import ContactLinksComponent from '../../components/ContactLinks/contactLinks.component';
import useLoadData from '../../hooks/useLoadData';
import { ContactBanner, ModulesSitic, strapiDoc } from '../../@types/strapiDocs';



const ModulesScreen: React.FC = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(16);
  const { data } = useLoadData<ModulesSitic>('/modulos-sitic', {
    populate: '*',
  });
  console.log('MODULES_PAGE', data);

  const query = {
    populate: {
      Icon: {
        populate: true,
      },
    },
  };

  const { data: modules } = useLoadData<strapiDoc[]>(
    'collections',
    query
  );
  console.log('MODULES', modules);

  // Cargar datos de `api::general.general`
  const { data: bannerData } = useLoadData<ContactBanner>('/contact-banner', {
    populate: {
      Options: {
        populate: {
          Icon: true, 
        },
      }
    },
  });

  console.log('Banner', bannerData);

  //***** Banner */
  const mapTypeToIconType = (type: string): 'support' | 'whatsapp' | 'email' | 'phone' => {
    switch (type.toLowerCase()) {  // Convertir a minúsculas para evitar problemas de capitalización
      case 'support':
        return 'support';
      case 'whatsapp':
        return 'whatsapp';
      case 'email':
        return 'email';
      case 'phone':
        return 'phone';
      default:
        console.error(`Unknown type: ${type}`);
        return 'support';  // Valor por defecto en caso de error
    }
  };

  const bannerTitle = bannerData?.attributes?.Title || '';
  const bannerDescription = bannerData?.attributes?.Description || '';
  const bannerLinks = bannerData?.attributes?.Options
  ?.filter((item) => item.Type) // Filtrar elementos sin `type`
  ?.map((item) => ({
    name: item.Name || '',
    type: mapTypeToIconType(item.Type!), // Type assertion as non-null
    url: item.Url || '',
    external: item.ExternalLink || ''
  })) || [];


  const title = data?.attributes?.Title || '';

  // Manejar el cambio de tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setItemsPerPage(6);
      } else {
        setItemsPerPage(16);
      }
    };

    // Escuchar el evento de cambio de tamaño
    window.addEventListener('resize', handleResize);

    // Establecer el tamaño inicial
    handleResize();

    // Limpiar el evento de cambio de tamaño
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Calcular el índice de los primeros y últimos elementos de la página actual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = modules?.slice(indexOfFirstItem, indexOfLastItem) || [];

  // Calcular el número total de páginas
  const totalPages = modules ? Math.ceil(modules.length / itemsPerPage) : 0;

  // Reiniciar a la primera página cuando cambie itemsPerPage
  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage]);

  return (
    <>
      <HeaderComponent />
      <HeroSmallComponent
        title={title}
        url="/"
      />
      <section className="module40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/*<SearchBarSimpleComponent
                namePlaceholder="Buscar en Módulos"
              />*/}
            </div>
          </div>
        </div>
      </section>
      <section className="module-bottom40">
        <div className="container">
          <div className="row">
            {currentItems && currentItems.map((module, index) => (
              <div className="col-lg-3 col-md-4 col-12 mb-4" key={index}>
                <CardTextComponent
                  title={module.attributes?.Title}
                  text={module.attributes?.Description}
                  icon={module.attributes?.Icon?.data?.attributes?.url}
                  url={module.attributes?.Slug}
                />
              </div>
            ))}
          </div>
        </div>
        <Paginator
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
          itemsPerPage={itemsPerPage}
          onItemsPerPageChange={setItemsPerPage}
          totalItems={modules ? modules.length : 0}
        />
      </section>
      <ContactLinksComponent
        title={bannerTitle}
        description={bannerDescription}
        links={bannerLinks}
      />
      <FooterComponent />
    </>
  );
};

export default ModulesScreen;
