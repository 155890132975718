import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import SkeletonLoaderImageComponent from '../Loader/Skeleton/skeletonImage.component';

interface CarouselItem {
  image: string;
  url?: string;
  externalLink?: boolean;
}

interface Props {
  items: CarouselItem[];
}

const CarouselComponent: React.FC<Props> = ({ items }) => {
  const [loading, setLoading] = useState(true);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="carousel">
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="carousel__item">
            {loading ? (
              <SkeletonLoaderImageComponent />
            ) : (
              <a href={item.url} target={item.externalLink ? '_blank' : '_self'} rel="noreferrer">
                <figure>
                  <img src={item.image} alt={`Carousel image ${index + 1}`} />
                </figure>
              </a>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselComponent;
