import React from 'react';
import ContentLoader from "react-content-loader"


type Props = {
  darkTheme?: boolean;
};

const SkeletonBodyLoaderComponent: React.FC<Props> = ({darkTheme = false}) => {

  return (
    <>
      <ContentLoader 
        speed={2}
        width={'100%'}
        height={1000}
        viewBox="0 0 1000 1000"
        backgroundColor={darkTheme ? "#4B90B9" : "#f3f3f3"}
        foregroundColor={darkTheme ? "#275EA7" : "#ecebeb"}
      >
        <rect x="0" y="0" rx="0" ry="0" width="100%" height="300" /> 
        <rect x="0" y="330" rx="3" ry="3" width="80%" height="6" /> 
        <rect x="0" y="350" rx="3" ry="3" width="80%" height="6" /> 
        <rect x="0" y="370" rx="3" ry="3" width="70%" height="6" /> 
        <rect x="0" y="390" rx="3" ry="3" width="70%" height="6" /> 
        <rect x="0" y="410" rx="3" ry="3" width="70%" height="6" /> 
        <rect x="0" y="430" rx="3" ry="3" width="70%" height="6" /> 
        <rect x="0" y="450" rx="3" ry="3" width="70%" height="6" /> 
      </ContentLoader>
    </>
  );
};

export default SkeletonBodyLoaderComponent;