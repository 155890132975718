import React from 'react';

interface Link {
  url: string;
  type: 'support' | 'whatsapp' | 'email' | 'phone';
  name?: string;
  externalLink?: boolean;
}

interface Props {
  title?: string;
  description?: string;
  links?: Link[];
}

const ContactLinksComponent: React.FC<Props> = ({ title, description, links = [] }) => {
  const getIconClass = (type: string) => {
    switch (type) {
      case 'support':
        return 'icon icon--support-color';
      case 'whatsapp':
        return 'icon icon--whatsapp-color';
      case 'email':
        return 'icon icon--email-color';
      case 'phone':
        return 'icon icon--phone-color';
      default:
        return '';
    }
  };

  return (
    <section className="module module--color3">
      <div className="container">
        <div className="row">
          {(title || description) && (
            <div className="col-12 text-center">
              {title && <div className="text-white" dangerouslySetInnerHTML={{ __html: title }} />}
              {description && <div className="text-blue200" dangerouslySetInnerHTML={{ __html: description }} />}
            </div>
          )}
          <div className="col-12 list-btn">
            {links.map((link, index) => (
              <a
                key={index} href={link.url}
                className="btn btn--link"
                target={link.externalLink ? '_self' : '_blank'} rel="noreferrer"
              >
                <i className={getIconClass(link.type)}></i> {link.name}
              </a>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactLinksComponent;
