import React from 'react';
import ContentLoader from "react-content-loader"


type Props = {
  darkTheme?: boolean;
};

const SkeletonLoaderComponent: React.FC<Props> = ({darkTheme = false}) => {

  return (
    <>
      <ContentLoader 
        speed={2}
        width={476}
        height={124}
        viewBox="0 0 476 124"
        backgroundColor={darkTheme ? "#4B90B9" : "#f3f3f3"}
        foregroundColor={darkTheme ? "#275EA7" : "#ecebeb"}
      >
        <rect x="0" y="8" rx="3" ry="3" width="410" height="6" /> 
        <rect x="0" y="26" rx="3" ry="3" width="380" height="6" /> 
        <rect x="0" y="46" rx="3" ry="3" width="178" height="6" /> 
      </ContentLoader>
    </>
  );
};

export default SkeletonLoaderComponent;