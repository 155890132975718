import { useState, useEffect } from 'react';
import { fetchStrapiSingleDynamic } from '../api/strapi';

interface PaginationOptions {
  page?: number;
  pageSize?: number;
}

interface FiltersOptions {
  Slug?: string | { $contains?: string };
  SuperTitle?: string | { $contains?: string };
  FiltersOptions?: string | { $contains?: string };
  Description?: string | { $contains?: string };
  Content?: {
    Slug: string | { $contains?: string };
  };
  Collection?: {
    Slug: string | { $contains?: string };
  };
  rols?: {
    rolId: { $in?: string[] };
  };
}

interface Options {
  pagination?: PaginationOptions;
  populate?: string | object;
  filters?: FiltersOptions;
  sort?: string[];
}

const useLoadData = <T,>(endpoint: string, options: Options = {}) => {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [pagination, setPagination] = useState({
    page: 1,
    pageSize: 0,
    pageCount: 0,
    total: 0,
  });

  const loadData = async () => {
    try {
      const { data: response, pagination } = await fetchStrapiSingleDynamic<T>(
        endpoint,
        options
      );
      setData(response);
      setPagination(pagination.pagination);
    } catch (err) {
      setError(`Failed to load ${endpoint}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Recarrega los datos cada vez que cambian `options` o `endpoint`
  useEffect(() => {
    loadData();
  }, [endpoint, JSON.stringify(options)]);

  useEffect(() => {
    if (pagination?.page) {
      loadData();
    }
  }, [options?.pagination?.page]);

  return { data, pagination, loading, error };
};

export default useLoadData;
