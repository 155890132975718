import axios from 'axios';
import qs from 'qs';
import { strapiDoc, strapiDocPaginate } from '../@types/strapiDocs';

const strapiBaseURL =
  process.env.REACT_APP_STRAPI_BASE_URL || 'http://54.226.21.179:1337/api';

const strapi = axios.create({
  baseURL: strapiBaseURL,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
  },
});

const fetchStrapiDocs = async (route: string, params: object) => {
  const query = qs.stringify(params, { encodeValuesOnly: true });
  const response = await strapi.get<strapiDocPaginate>(`${route}?${query}`);
  return response.data;
};

const fetchStrapiSingle = async (route: string, params: object) => {
  const query = qs.stringify(params);
  console.log(query);
  const response = await strapi.get<{
    data: strapiDoc;
  }>(`${route}?${query}`);
  return response.data;
};

const fetchStrapiSingleDynamic = async <T>(
  route: string,
  params: object
): Promise<{ data: T; pagination: strapiDocPaginate }> => {
  // const query = qs.stringify(params);
  const query = qs.stringify(params, { encode: false });
  const response = await strapi.get<{ data: T; meta: strapiDocPaginate }>(
    `${route}?${query}`
  );
  return {
    data: response.data.data,
    pagination: response.data.meta,
  };
};

export { fetchStrapiDocs, fetchStrapiSingle, fetchStrapiSingleDynamic };
