import React, { useMemo } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import useLoadData from '../../hooks/useLoadData';
import { strapiDoc } from '../../@types/strapiDocs';

interface Props {
  name?: string;
  collection?: string;
}

const AccordionComponent: React.FC<Props> = ({ name, collection }) => {
  const { data: moduleSection } = useLoadData<strapiDoc[]>(`/module-sections`, {
    populate: '*',
    sort: ['id'],
  });

  const { data: downloads } = useLoadData<strapiDoc[]>(`/downloads`, {
    populate: '*',
    sort: ['id'],
    filters: { Collection: { Slug: collection || '' } },
  });

  const options = useMemo(() => {
    const filters = { Collection: { Slug: collection || '' } };
    const clientRolsIds: string[] = JSON.parse(
      localStorage.getItem('clientRolsIds') || '[]'
    );
    if (clientRolsIds.length > 0) {
      const query = {
        filters: { ...filters, rols: { rolId: { $in: clientRolsIds } } },
      };
      console.log('useMemo - QUERY', query);
      return query;
    }
    return { filters };
  }, [collection]);

  const { data: module } = useLoadData<strapiDoc[]>(`/modules`, {
    populate: '*',
    ...options,
  });

  console.log('MODULE SECTION', module);
  console.log('DOWNLOADS', downloads);

  const downloadLink = (downloads && downloads[0]?.attributes?.Slug) || '';

  return (
    <>
      <Accordion defaultActiveKey="0" className="main-accordion">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Inicio</Accordion.Header>
          <Accordion.Body>
            <ul className="list-links">
              <li>
                <a href={`/${collection}`} className="text-icon active">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="home">
                      <path
                        id="Vector"
                        d="M9.99935 2.5L1.66602 10H4.16602V16.6667H9.16602V11.6667H10.8327V16.6667H15.8327V10H18.3327L9.99935 2.5ZM14.166 15H12.4993V10H7.49935V15H5.83268V8.49167L9.99935 4.74167L14.166 8.49167V15Z"
                        fill="#002739"
                      />
                      <path
                        id="Vector_2"
                        opacity="0.3"
                        d="M5.83398 8.4917V15H7.50065V10H12.5007V15H14.1673V8.4917L10.0007 4.7417L5.83398 8.4917Z"
                        fill="#002739"
                      />
                    </g>
                  </svg>
                  Bienvenido a {name}
                </a>
              </li>
              {downloadLink && (
                <li>
                  <a
                    href={`/${collection}/downloads/${downloadLink}`}
                    className="text-icon"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M10.0007 13.3335L5.83398 9.16683L7.00065 7.9585L9.16732 10.1252V3.3335H10.834V10.1252L13.0007 7.9585L14.1673 9.16683L10.0007 13.3335ZM5.00065 16.6668C4.54232 16.6668 4.14996 16.5036 3.82357 16.1772C3.49718 15.8509 3.33398 15.4585 3.33398 15.0002V12.5002H5.00065V15.0002H15.0007V12.5002H16.6673V15.0002C16.6673 15.4585 16.5041 15.8509 16.1777 16.1772C15.8513 16.5036 15.459 16.6668 15.0007 16.6668H5.00065Z"
                        fill="#85B0CB"
                      />
                    </svg>
                    Descargables del módulo
                  </a>
                </li>
              )}
            </ul>
          </Accordion.Body>
        </Accordion.Item>
        {module?.length !== 0 && (
          <Accordion.Item eventKey="1">
            <Accordion.Header>Contenido</Accordion.Header>
            <Accordion.Body>
              <Accordion defaultActiveKey="0" className="sub-accordion">
                {moduleSection?.map(
                  (section) =>
                    (module?.length ?? 0) > 0 && (
                      <Accordion.Item
                        key={section.id}
                        eventKey={`${section.id}`}
                      >
                        <Accordion.Header>
                          {section.attributes.Title}
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul className="list-links">
                            {(module ?? [])
                              .filter((mod) =>
                                mod.attributes.Section?.data
                                  ?.map((s) => s.attributes.Slug)
                                  .includes(section.attributes.Slug || '')
                              )
                              .map((mod) => (
                                <li key={mod.id}>
                                  <a
                                    href={`/${collection}/${mod.attributes.Slug}`}
                                    className="text-icon"
                                    onClick={(event) => {
                                      event.preventDefault();
                                      window.location.href = `/${collection}/${mod.attributes.Slug}`;
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="20"
                                      height="20"
                                      viewBox="0 0 20 20"
                                      fill="none"
                                    >
                                      <path
                                        d="M6.66732 14.9998H13.334V13.3332H6.66732V14.9998ZM6.66732 11.6665H13.334V9.99984H6.66732V11.6665ZM5.00065 18.3332C4.54232 18.3332 4.14996 18.17 3.82357 17.8436C3.49718 17.5172 3.33398 17.1248 3.33398 16.6665V3.33317C3.33398 2.87484 3.49718 2.48248 3.82357 2.15609C4.14996 1.8297 4.54232 1.6665 5.00065 1.6665H11.6673L16.6673 6.6665V16.6665C16.6673 17.1248 16.5041 17.5172 16.1777 17.8436C15.8513 18.17 15.459 18.3332 15.0007 18.3332H5.00065ZM10.834 7.49984V3.33317H5.00065V16.6665H15.0007V7.49984H10.834Z"
                                        fill="#85B0CB"
                                      />
                                    </svg>
                                    <span>{mod.attributes.SuperTitle}</span>
                                  </a>
                                </li>
                              ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                )}
              </Accordion>
            </Accordion.Body>
          </Accordion.Item>
        )}
      </Accordion>
    </>
  );
};

export default AccordionComponent;
