import React, { useRef, useState, useEffect } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import swal from 'sweetalert2';
import ModalShareComponent from '../Modal/modalShare.component';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

interface Props {
  title?: string;
  id?: string;
  image?: string;
  description?: string;
}

const ArticleContentComponent: React.FC<Props> = ({ title, id, image, description }) => {
  const [showShare, setShowShare] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = React.useState(false);

  const shareTarget = useRef(null);
  const componentId = id; // ID del div padre

  const copyLinkToClipboard = () => {
    const url = `${window.location.href.split('#')[0]}#${componentId}`;
    navigator.clipboard.writeText(url).then(() => {
      swal.fire({
        title: 'Link copiado',
        text: 'El link ha sido copiado al portapapeles',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    }, (err) => {
      console.error('Error al copiar el link: ', err);
    });
  };


  const handleModalClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showShare) {
      setShowOptions(false);
    }
    if (showOptions) {
      setShowShare(false);
    }
  }, [showShare, showOptions]);

  return (
    <>
      <ModalShareComponent
        show={showModal}
        handleClose={handleModalClose}
      />
      <section id={id} className="central-content">
        <div className="head-content">
          <div className="col-lg-8 col-12">
            {title && <div className="h4 text-blue700 col-lg-12 col-8" dangerouslySetInnerHTML={{__html: title}}/>}
          </div>
          <aside className="shared">
            <button
              type="button"
              className="liker__button-btn share"
              ref={shareTarget}
              onClick={() => setShowShare(!showShare)}
            >
              <i className="icon icon--share"></i>
            </button>
            <Overlay
              target={shareTarget.current}
              show={showShare}
              placement="bottom"
            >
              <div onMouseLeave={() => setShowShare(false)}>
                <div className="overlay-element">
                  <div>
                    <button
                      className="btn btn--tag"
                      onClick={copyLinkToClipboard}
                    >
                      <i className="icon icon--info"></i> Copiar link
                    </button>
                    <button
                      className="btn btn--tag"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="icon icon--share-small"></i> Compartir por correo
                    </button>
                  </div>
                </div>
              </div>
            </Overlay>
            

          </aside>
        </div>
        {(description || image) &&  <section className="content-module-article">
          {description && <div dangerouslySetInnerHTML={{__html: description}} />}
          
          {image &&   <figure>
            <button type="button" onClick={() => setOpen(true)}>
              <img src={image} alt={title} />
            </button>
            <Lightbox
              open={open}
              close={() => setOpen(false)}
              slides={[
                { src: image },
              ]}
            />
          </figure>}
        
        </section>}
       
      </section>
    </>
  );
};

export default ArticleContentComponent;
