import React, { useState } from 'react';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { useNavigate } from 'react-router-dom';

interface Props {
  itemsModules: { value: string, label: string }[];
  options: { value: string, label: string }[];
  showChecker?: boolean;
  moduleSlug?: string;
}

const SearchBarComponent: React.FC<Props> = ({ options, itemsModules, showChecker = true, moduleSlug = 'todos' }) => {
  const navigate = useNavigate();
  const [isListVisible, setIsListVisible] = useState(false);
  const [selectedType, setSelectedType] = useState('todos');

  const handleItemClick = (value: string) => {
    setSelectedType(value);
    setIsListVisible(false);
  };

  const handleCheckerClick = () => {
    setIsListVisible(!isListVisible);
  };

  const handleMouseLeave = () => {
    setIsListVisible(false);
  };

  const handleSearchSubmit = (searchTerm: string) => {
    if (searchTerm) {
      navigate(`/resultado-de-busqueda?search=${encodeURIComponent(searchTerm)}&type=${encodeURIComponent(selectedType)}&module=${encodeURIComponent(moduleSlug)}`);
    }
  };


  const handleOnSelect = (item: { id: number; name: string }) => {
    handleSearchSubmit(item.name); // Redirige con el término seleccionado
  };

  const formatResult = (item: { id: number; name: string }) => {
    return <span>{item.name}</span>;
  };

  const enhancedOptions = [{ value: 'todos', label: 'Todos' }, ...itemsModules];
  const selectedLabel = enhancedOptions.find(option => option.value === selectedType)?.label;

  console.log(options)

  return (
    <div className="search-bar" onMouseLeave={handleMouseLeave}>
       {showChecker && (
        <div className="search-bar__checker" onClick={handleCheckerClick}>
          <div className="search-bar__item-checked">
            <div>
              <span>{selectedLabel}</span>
              <span className="material-symbols-outlined icon">keyboard_arrow_down</span>
            </div>
          </div>
          {isListVisible && (
            <div className="search-bar__list">
              {enhancedOptions.map(option => (
                <div className="search-bar__item" key={option.value}>
                  <input
                    type="radio"
                    name="search"
                    value={option.value}
                    id={option.value}
                    checked={selectedType === option.value}
                    onChange={() => handleItemClick(option.value)}
                  />
                  <label htmlFor={option.value}>{option.label}</label>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
      <ReactSearchAutocomplete
        items={options.map((opt, idx) => ({ id: idx, name: opt.label }))}
        onSearch={(string, results) => {
          // Lógica opcional para manejar búsqueda en tiempo real
          console.log('Search string:', string);
          console.log('Results:', results);
        }}
        onSelect={handleOnSelect}
        autoFocus
        formatResult={formatResult}
        placeholder="Buscar"
        styling={{ zIndex: 3 }}
      />
      <button type="submit" className="btn" onClick={() => handleSearchSubmit(selectedLabel || '')}>
        <span className="material-symbols-outlined">search</span>
      </button>
    </div>
  );
};

export default SearchBarComponent;
