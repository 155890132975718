import React from 'react';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import HeroSmallComponent from '../../components/HeroImage/heroSmall.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchBarSimpleComponent from '../../components/SearchBar/searchBarSimple.component';
import AccordionComponent from '../../components/Accordion/accordion.component';
import { useParams } from 'react-router-dom';
import { strapiDoc } from '../../@types/strapiDocs';
import useLoadData from '../../hooks/useLoadData';
import SkeletonBodyLoaderComponent from '../../components/Loader/Skeleton/skeletonBody.component';
import CentralContentMainComponent from '../../components/Content/centralContentMain.component';

const ModuleDownloadsScreen: React.FC = () => {
  const { collection, module } = useParams();

  // Asegúrate de que la URL esté construida correctamente, sin duplicar "api"
  const endpoint = `/downloads`;

  const { data, loading, error } = useLoadData<strapiDoc[]>(
    endpoint || '', // Pasa la URL correctamente
    {
      populate: {
        DownloadLink: true,
        Collection: {
          populate: {
            Image: true
          }
        }
       
      },
      filters: { Collection: { Slug: collection || '' }, Slug: module },
    }
  );

  console.log('collection', collection); // Verifica que sea "ventas"
  console.log('Fetching from:', endpoint);
  console.log('DOWNLOAD DATA', data);
  console.log('ERROR', error);

  if (loading)
    return (
      <div className="container-fluid pt-3">
        <SkeletonBodyLoaderComponent />
      </div>
    );
  if (error)
    return <div className="badge bg-danger">Error loading data: {error}</div>;

  // Collection
  const collectionName =
    (data && data[0]?.attributes?.Collection?.data[0]?.attributes?.Title) || '';
  
  const collectionDescription =
    (data &&
      data[0]?.attributes?.Collection?.data[0]?.attributes?.Description) ||
    '';
  const collectionImage =
    (data &&
      data[0]?.attributes?.Collection?.data[0]?.attributes?.Image?.data
        ?.attributes?.url) ||
    '';
  
  // Content
  const title = (data && data[0]?.attributes?.Title) || '';
  const description = (data && data[0]?.attributes?.Description) || '';
  const downloads = data?.[0]?.attributes?.DownloadLink?.map((item) => ({
    name: item.Name || '',
    url: item.Url || '',
    external: item.ExternalLink || false
  })) || [];

  return (
    <>
      <HeaderComponent theme="light" />
      <HeroSmallComponent
        title={collectionName}
        theme="color"
        description={collectionDescription}
        imageUrl={collectionImage}
        url="/"
      />
      <section className="module">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 col-12 mb-4">
              <div className="aside-stick">
                {/*<SearchBarSimpleComponent namePlaceholder="Buscar en Videos" />*/}
                <AccordionComponent
                  name={(collection ?? '').charAt(0).toUpperCase() + (collection ?? '').slice(1).toLowerCase()}
                  collection={collection}
                />
              </div>
            </aside>
            <section className="col-lg-9 col-12">
              <div className="content-main">
                <section className="content-block">
                 
                  <CentralContentMainComponent
                     title={title}
                     description={description}
                  />
                  {downloads.length > 0 && <>
                    <ul className="list-links">
                    
                    
                    {downloads.map((item, index) => ( 
                      <li key={index}>
                        <a 
                          href={item.url}
                          target={item.external ? '_blank' : '_self'}
                          rel="noreferrer"
                          className="text-icon"
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M6.66732 14.9998H13.334V13.3332H6.66732V14.9998ZM6.66732 11.6665H13.334V9.99984H6.66732V11.6665ZM5.00065 18.3332C4.54232 18.3332 4.14996 18.17 3.82357 17.8436C3.49718 17.5172 3.33398 17.1248 3.33398 16.6665V3.33317C3.33398 2.87484 3.49718 2.48248 3.82357 2.15609C4.14996 1.8297 4.54232 1.6665 5.00065 1.6665H11.6673L16.6673 6.6665V16.6665C16.6673 17.1248 16.5041 17.5172 16.1777 17.8436C15.8513 18.17 15.459 18.3332 15.0007 18.3332H5.00065ZM10.834 7.49984V3.33317H5.00065V16.6665H15.0007V7.49984H10.834Z" fill="#85B0CB"></path></svg>
                          <span>{item.name}</span></a>
                      </li>
                    ))}
                    </ul>
                  </>}
                </section>
                
              </div>
            </section>
          </div>
        </div>
      </section>
     
      <FooterComponent />
    </>
  );
};

export default ModuleDownloadsScreen;
