import React, { useEffect, useState } from 'react';
import SkeletonLoaderComponent from '../Loader/Skeleton/skeleton.component';

interface Props {
  title?: string;
  text?: string;
  icon?: string;
  url?: string;
}

const CardTextComponent: React.FC<Props> = ({ title, text, icon, url }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);


  return (
    <div className="card-text">
      {loading ? (
        <SkeletonLoaderComponent/>
      ) : (
        <>
          <a href={url}>
            {(title || icon) &&  <div className="card-text__top">
              {title && <h3 className="h4" dangerouslySetInnerHTML={{ __html: title}}/>}
              {icon && <div className="card__icon-small"><img src={icon} alt="Icon"/></div>}
            </div>}
            {text && <article className="text-14 text-gray600" dangerouslySetInnerHTML={{ __html: text}} />}
          </a>
        </>
      )}
      
    </div>
  );
};

export default CardTextComponent;