import React from 'react';

interface PaginatorProps {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  itemsPerPage: number;
  onItemsPerPageChange: (itemsPerPage: number) => void;
  totalItems: number;
}

const Paginator: React.FC<PaginatorProps> = ({
  currentPage,
  totalPages,
  onPageChange,
  itemsPerPage,
  onItemsPerPageChange,
  totalItems
}) => {
  const handleFirstPage = () => onPageChange(1);
  const handlePreviousPage = () => onPageChange(Math.max(currentPage - 1, 1));
  const handleNextPage = () => onPageChange(Math.min(currentPage + 1, totalPages));
  const handleLastPage = () => onPageChange(totalPages);

  const handleItemsPerPageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onItemsPerPageChange(Number(event.target.value));
  };

  const indexOfFirstItem = (currentPage - 1) * itemsPerPage + 1;
  const indexOfLastItem = Math.min(currentPage * itemsPerPage, totalItems);

  return (
    <section className="module40 d-flex justify-content-center">
      <div className="pagination">
        <label className="pagination__label text-gray500">
          <span>Elementos por página: </span>
          <select
            className="form-select"
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
          >
            <option value={6}>6</option>
            <option value={16}>16</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </label>
        <span className="pagination__desc text-gray500">{indexOfFirstItem}-{indexOfLastItem} de {totalItems}</span>
        <nav className="pagination__nav">
          <button onClick={handleFirstPage} disabled={currentPage === 1}>
            <span className="material-symbols-outlined">first_page</span>
          </button>
          <button onClick={handlePreviousPage} disabled={currentPage === 1}>
            <span className="material-symbols-outlined">keyboard_arrow_left</span>
          </button>
        
          <button onClick={handleNextPage} disabled={currentPage === totalPages}>
            <span className="material-symbols-outlined">keyboard_arrow_right</span>
          </button>
          <button onClick={handleLastPage} disabled={currentPage === totalPages}>
            <span className="material-symbols-outlined">last_page</span>
          </button>
        </nav>
      </div>
    </section>
  );
};

export default Paginator;
