import React from 'react';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';

const NotFoundScreen: React.FC = () => {
  return (
    <>
      <HeaderComponent
        theme="light"
      />
      <section className="simple-page page--notfound">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="module text-center">
                <p className="icon-big text-blue400"><span className="material-symbols-outlined">emergency_home</span></p>
                <h1 className="text-blue700">Página no encontrada</h1>
                <p className="text-21">La página que buscas no existe o fue cambiada, puedes regresar al inciio <a href="/">aquí</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

export default NotFoundScreen;