import React, {useState, useEffect} from 'react';
import SkeletonLoaderComponent from '../Loader/Skeleton/skeleton.component';
import SkeletonLoaderImageComponent from '../Loader/Skeleton/skeletonImage.component';

interface Props {
  imageUrl: string;
  title?: string;
  text?: string;
  url?: string;
  icon?: string;
}

const CardImageComponent: React.FC<Props> = ({ imageUrl, title, text, url, icon }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <div className="card-image">
        {loading ? (
          <SkeletonLoaderImageComponent />
        ) : (
          <figure className="card-image__figure">
            <img src={imageUrl} alt={title} />
          </figure>)
        }
     
      <div className="card-image__text">
        {loading ? (
          <SkeletonLoaderComponent darkTheme={true}/>
        ) : (
          <>
            {icon && <div className="card-image__icon"><img src={icon} alt="Icon"/></div>}
            {title && <h3>{title}</h3>}
            {text && <article  dangerouslySetInnerHTML={{
                      __html: text,
                    }}/>}
            {url && <a href={url} className="btn btn--simple-icon">Explorar <i className="icon icon--arrow-right"></i></a>}
          </>
        )}
       
        
      </div>
    </div>
  );
};

export default CardImageComponent;