import React, { useState } from 'react';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import HeroSmallComponent from '../../components/HeroImage/heroSmall.component';
import SearchBarSimpleComponent from '../../components/SearchBar/searchBarSimple.component';
import useLoadData from '../../hooks/useLoadData';
import { strapiDoc } from '../../@types/strapiDocs';
import SkeletonBodyLoaderComponent from '../../components/Loader/Skeleton/skeletonBody.component';
import { useParams } from 'react-router-dom';
import ListComponent from '../../components/List/list.component';
import SkeletonLoaderComponent from '../../components/Loader/Skeleton/skeleton.component';
import Paginator from '../../components/Paginator/paginator.component';

interface CollectionAttributes {
  Slug: string;
  Title: string;
}

const TypeScreen: React.FC = () => {
  const [selectedCollection, setSelectedCollection] = useState<string>('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState('');
  const { collection } = useParams();

  const endpoint = `/content-types`;

  const { data, loading, error } = useLoadData<strapiDoc[]>(
    endpoint,
    {
      populate: '*',
      filters: { Slug: collection },
    }
  );

  const { data: modules, loading: modulesLoading, error: modulesError } = useLoadData<strapiDoc[]>(
    '/modules',
    {
      populate: '*',
    }
  );

  const handleSelectModule = (selectedModule: { id: number; name: string }) => {
    const matchedModule = modules?.find(
      (module) => module.attributes?.SuperTitle === selectedModule.name
    );
    if (matchedModule) {
      setSelectedCollection(
        matchedModule.attributes.Collection?.data[0]?.attributes.Slug || ''
      );
    }
  };

  const handleSearchChange = (term: string) => {
    setSearchTerm(term);
    setCurrentPage(1);
  };

  const handleCollectionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCollection(event.target.value);
    setCurrentPage(1);
  };

  const uniqueCollections = modules?.reduce((acc: CollectionAttributes[], module) => {
    const collection = module.attributes.Collection?.data[0]?.attributes;
    if (collection && !acc.find((col) => col.Slug === collection.Slug)) {
      acc.push({
        Slug: collection.Slug,
        Title: collection.Title,
      });
    }
    return acc;
  }, []);

  const filteredModules = modules?.filter((module) => {
    const title = module.attributes?.SuperTitle || '';
    const description = module.attributes?.SneakPeak || '';
    return (
      title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      description.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const displayedModules = selectedCollection
    ? filteredModules?.filter(module => module.attributes.Collection?.data[0]?.attributes.Slug === selectedCollection)
    : filteredModules;

  const totalPages = Math.ceil((displayedModules?.length ?? 0) / itemsPerPage);
  const paginatedModules = displayedModules?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const title = data && data[0]?.attributes?.Title || '';

  if (loading) return <div className="container-fluid pt-3"><SkeletonBodyLoaderComponent /></div>;
  if (error || modulesError) return <div className="badge bg-danger">Error loading data: {error || modulesError}</div>;

  return (
    <>
      <HeaderComponent />
      <HeroSmallComponent title={title} url="/" />
      <section className="module40">
        <div className="container">
          <div className="row">
            <div className="col-12 double-element">
              <SearchBarSimpleComponent
                namePlaceholder="Buscar en Videos"
                modules={(modules?.map((module, index) => ({
                  id: index,
                  name: module.attributes?.SuperTitle || `Untitled ${index + 1}`,
                })) ?? [])}
                onSelectModule={handleSelectModule}
                onSearchChange={handleSearchChange}
              />
              <select className="form-select" onChange={handleCollectionChange}>
                <option value="">Todos los Módulos</option>
                {uniqueCollections?.map((collection, index) => (
                  <option key={index} value={collection.Slug}>
                    {collection.Title}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </section>
      <section className="module40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {modulesLoading ? (
                <SkeletonLoaderComponent />
              ) : displayedModules?.length === 0 ? (
                <div className="alert alert-warning">No se encontraron resultados relacionados</div>
              ) : (
                paginatedModules?.map((module, index) => (
                  <div className="col-lg-6 col-12 mb-4" key={index}>
                    <ListComponent
                      type={module.attributes?.Collection?.data[0]?.attributes?.Title}
                      title={module.attributes.SuperTitle}
                      description={module.attributes?.SneakPeak}
                      url={`/${module.attributes?.Collection?.data[0]?.attributes?.Slug}/${module.attributes?.Slug}`}
                    />
                  </div>
                ))
              )}
            </div>
          </div>
          {/* Muestra el paginador solo si hay resultados */}
          {(displayedModules?.length ?? 0) > 0 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={setItemsPerPage}
              totalItems={displayedModules?.length ?? 0}
            />
          )}
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

export default TypeScreen;
