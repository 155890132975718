import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import HeroSmallComponent from '../../components/HeroImage/heroSmall.component';
import ListComponent from '../../components/List/list.component';
import Paginator from '../../components/Paginator/paginator.component';
import useLoadData from '../../hooks/useLoadData';
import useDebounce from '../../hooks/useDebounce';
import { strapiDoc } from '../../@types/strapiDocs';

interface CollectionAttributes {
  Slug: string;
  Title: string;
}

interface Filters {
  SuperTitle?: { $contains?: string };
  $or?: Array<
    { Collection?: { Slug: string } } | { Content?: { Slug: string } }
  >;
  Collection?: { Slug: string };
  rols?: { rolId: { $in?: string[] } };
}

const SearchScreen: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchResults, setSearchResults] = useState<strapiDoc[]>([]);

  // Obtener parámetros de búsqueda de la URL
  const searchTerm = new URLSearchParams(location.search).get('search') || '';
  const selectedType =
    new URLSearchParams(location.search).get('type') || 'todos';
  const selectedModule =
    new URLSearchParams(location.search).get('module') || 'todos';

  // Estado que mantiene el valor del módulo seleccionado en el select
  const [selectedCollection, setSelectedCollection] = useState(selectedModule);

  // Aplicar debounce a selectedCollection
  const debouncedCollection = useDebounce(selectedCollection, 300);

  const options = useMemo(() => {
    const filters: Filters = {
      SuperTitle: { $contains: searchTerm },
    };
    const clientRolsIds = JSON.parse(
      localStorage.getItem('clientRolsIds') || '[]'
    );

    if (selectedType !== 'todos') {
      filters.$or = [
        { Collection: { Slug: selectedType } },
        { Content: { Slug: selectedType } },
      ];
    }

    if (selectedModule !== 'todos') {
      filters.Collection = { Slug: selectedModule };
    }

    if (clientRolsIds.length > 0) {
      filters.rols = { rolId: { $in: clientRolsIds } };
    }

    return {
      populate: '*',
      filters,
    };
  }, [searchTerm, selectedType, selectedModule]);

  const { data, loading, error } = useLoadData<strapiDoc[]>(
    '/modules',
    options
  );

  console.log(options);
  console.log(data);
  console.log(debouncedCollection);

  useEffect(() => {
    if (data) setSearchResults(data);
  }, [data]);

  // Cargar datos de módulos para obtener las colecciones únicas y los contenidos únicos
  const { data: modules } = useLoadData<strapiDoc[]>('/modules', {
    populate: '*',
  });

  const uniqueCollections = modules?.reduce(
    (acc: CollectionAttributes[], module) => {
      const collection = module.attributes.Collection?.data[0]?.attributes;
      if (collection && !acc.find((col) => col.Slug === collection.Slug)) {
        acc.push({ Slug: collection.Slug, Title: collection.Title });
      }
      return acc;
    },
    []
  );

  const uniqueContent = modules?.reduce(
    (acc: CollectionAttributes[], module) => {
      const content = module.attributes.Content?.data[0]?.attributes;
      if (content && !acc.find((cont) => cont.Slug === content.Slug)) {
        acc.push({ Slug: content.Slug, Title: content.Title });
      }
      return acc;
    },
    []
  );

  // Maneja el cambio de la colección seleccionada
  const handleCollectionChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedModuleSlug = event.target.value;
    setSelectedCollection(selectedModuleSlug);
    setCurrentPage(1);

    // Actualizar la URL con el nuevo `module`
    const newUrl = `/resultado-de-busqueda?search=${encodeURIComponent(
      searchTerm
    )}&type=${encodeURIComponent(selectedType)}&module=${encodeURIComponent(
      selectedModuleSlug
    )}`;
    navigate(newUrl);
  };

  // Controlar paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = searchResults.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(searchResults.length / itemsPerPage);

  useEffect(() => {
    setCurrentPage(1);
  }, [itemsPerPage, searchTerm, selectedType, debouncedCollection]);

  return (
    <>
      <HeaderComponent theme="light" />
      <HeroSmallComponent
        theme="light"
        url="/"
        title="Resultados de la búsqueda"
        font="medium"
        size="large"
      />
      <section className="module-bottom40">
        <div className="container">
          <div className="row">
            <div className="col-12 double-element double--left">
              <article>
                <p className="h4 text-500 text-gray600 mb-0">
                  <em>{`"${searchTerm}"`}</em>
                </p>
                <p className="text-blue300">
                  <strong>{searchResults.length} coincidencias</strong>
                </p>
              </article>
              <div className="col-md-4 col-12 double-element__inner">
                <select
                  className="form-select"
                  value={selectedType}
                  onChange={(e) => {
                    const type = e.target.value;
                    const url = `/resultado-de-busqueda?search=${encodeURIComponent(
                      searchTerm
                    )}&type=${encodeURIComponent(
                      type
                    )}&module=${encodeURIComponent(selectedCollection)}`;
                    navigate(url);
                  }}
                >
                  <option value="todos">Todos</option>
                  {uniqueContent?.map((content, index) => (
                    <option key={index} value={content.Slug}>
                      {content.Title}
                    </option>
                  ))}
                </select>
                <select
                  className="form-select"
                  value={selectedCollection}
                  onChange={handleCollectionChange}
                >
                  <option value="todos">Todos los Módulos</option>
                  {uniqueCollections?.map((collection, index) => (
                    <option key={index} value={collection.Slug}>
                      {collection.Title}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="module-bottom40">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {loading ? (
                <p>Cargando resultados...</p>
              ) : error ? (
                <div className="alert alert-danger">
                  Error al cargar los resultados
                </div>
              ) : searchResults.length === 0 ? (
                <div className="alert alert-warning">
                  No se encontraron resultados para {searchTerm}.
                </div>
              ) : (
                currentItems.map((module, index) => {
                  const url = `/${module.attributes?.Collection?.data[0]?.attributes?.Slug}/${module.attributes?.Slug}`;
                  return (
                    <div className="col-lg-6 col-12 mb-4" key={index}>
                      <ListComponent
                        type={
                          module.attributes?.Collection?.data[0]?.attributes
                            ?.Title || ''
                        }
                        title={module.attributes?.SuperTitle || ''}
                        description={module.attributes?.SneakPeak || ''}
                        url={url || ''}
                      />
                    </div>
                  );
                })
              )}
            </div>
          </div>
          {searchResults.length > 0 && (
            <Paginator
              currentPage={currentPage}
              totalPages={totalPages}
              onPageChange={setCurrentPage}
              itemsPerPage={itemsPerPage}
              onItemsPerPageChange={setItemsPerPage}
              totalItems={searchResults.length}
            />
          )}
        </div>
      </section>
      <FooterComponent />
    </>
  );
};

export default SearchScreen;
