import React, { useMemo } from 'react';
import HeaderComponent from '../../Shared/header.shared';
import FooterComponent from '../../Shared/footer.shared';
import HeroSmallComponent from '../../components/HeroImage/heroSmall.component';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchBarSimpleComponent from '../../components/SearchBar/searchBarSimple.component';
import AccordionComponent from '../../components/Accordion/accordion.component';
import CentralContentMainComponent from '../../components/Content/centralContentMain.component';
import TopicsComponent from '../../components/Related/topics.component';
import VideoPlayer from '../../components/VideoPlayer/videoPlayer.component';
import { strapiDoc } from '../../@types/strapiDocs';
import useLoadData from '../../hooks/useLoadData';
import { useParams } from 'react-router-dom';
import SkeletonBodyLoaderComponent from '../../components/Loader/Skeleton/skeletonBody.component';
import SearchBarComponent from '../../components/SearchBar/searchBar.component';

const ModuleScreen: React.FC = () => {
  const { collection } = useParams();

  // Asegúrate de que la URL esté construida correctamente, sin duplicar "api"
  const endpoint = `/collections`;

  const { data, loading, error } = useLoadData<strapiDoc[]>(
    endpoint || '', // Pasa la URL correctamente
    {
      populate: '*',
      filters: { Slug: collection },
    }
  );

  const options = useMemo(() => {
    const clientRolsIds: string[] = JSON.parse(
      localStorage.getItem('clientRolsIds') || '[]'
    );
    if (clientRolsIds.length) {
      return {
        populate: '*',
        filters: { rols: { rolId: { $in: clientRolsIds } } },
      };
    }
    return {
      populate: '*',
    };
  }, []);

  // Cambiamos el endpoint a `/modules` para cargar solo los módulos
  const { data: modulesData } = useLoadData<strapiDoc[]>('/modules', options);

  console.log('collection', collection); // Verifica que sea "ventas"
  console.log('Fetching from:', endpoint);
  console.log('MODULE INDEX', data);
  console.log('ERROR', error);

  if (loading)
    return (
      <div className="container-fluid pt-3">
        <SkeletonBodyLoaderComponent />
      </div>
    );
  if (error)
    return <div className="badge bg-danger">Error loading data: {error}</div>;

  // Content
  const slug = (data && data[0]?.attributes?.Slug) || '';
  const title = (data && data[0]?.attributes?.Title) || '';
  const description = (data && data[0]?.attributes?.Description) || '';
  const intro = (data && data[0]?.attributes?.IntroText) || '';
  const video = (data && data[0]?.attributes?.Video) || '';
  const image =
    (data && data[0]?.attributes?.Image?.data?.attributes.url) || '';
  const related =
    (data &&
      data[0]?.attributes?.related?.data.map((item) => ({
        slug: item.attributes?.Slug || '',
        title: item.attributes?.SuperTitle || '',
      }))) ||
    [];

  console.log(slug);

  // Transformar `related` en la estructura que espera `ArticleComponent`
  const relatedLinks = related.map((item) => ({
    href: `/${slug}/${item.slug}`, // Construye la URL usando el slug
    text: item.title, // Usa el título para el texto del link
  }));

  const searchOptions =
    modulesData?.map((module) => ({
      value:
        module.attributes?.Slug ||
        module.attributes?.Title?.toLowerCase() ||
        'untitled',
      label: module.attributes?.SuperTitle || 'Untitled',
    })) || [];

  return (
    <>
      <HeaderComponent theme="light" />
      <HeroSmallComponent
        title={title}
        theme="color"
        description={description}
        imageUrl={image}
        url="/"
      />
      <section className="module">
        <div className="container">
          <div className="row">
            <aside className="col-lg-3 col-12 mb-4">
              <div className="aside-stick">
                <SearchBarComponent
                  options={searchOptions}
                  itemsModules={searchOptions} // Pasa todos los módulos para seleccionar
                  moduleSlug={slug} // Parámetro `module` con el slug actual
                  showChecker={false} // Desactiva el checker si es necesario
                />
                <AccordionComponent name={title} collection={collection} />
              </div>
            </aside>
            <section className="col-lg-9 col-12">
              <div className="content-main">
                <CentralContentMainComponent
                  title={`Bienvenido a ${title}`}
                  description={intro}
                />
                {video && <VideoPlayer videoUrl={video} tag={true} />}
                <div className="related-content">
                  <div className="row">
                    {relatedLinks.length > 0 && (
                      <div className="col-lg-7 col-12 mb-3">
                        <TopicsComponent
                          title="Temas más consultados"
                          links={relatedLinks}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>

      <FooterComponent />
    </>
  );
};

export default ModuleScreen;
