import React from 'react';

interface Link {
  href: string;
  text: string;
}

interface Props {
  title?: string;
  links: Link[];
}

const TopicsComponent: React.FC<Props> = ({ title, links }) => {
  return (
    <div className="content-secondary">
      <h3 className="mb-3">{title || 'Temas más consultados'}</h3>
      <ul className="list-links">
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.href} className="text-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M6.66732 14.9998H13.334V13.3332H6.66732V14.9998ZM6.66732 11.6665H13.334V9.99984H6.66732V11.6665ZM5.00065 18.3332C4.54232 18.3332 4.14996 18.17 3.82357 17.8436C3.49718 17.5172 3.33398 17.1248 3.33398 16.6665V3.33317C3.33398 2.87484 3.49718 2.48248 3.82357 2.15609C4.14996 1.8297 4.54232 1.6665 5.00065 1.6665H11.6673L16.6673 6.6665V16.6665C16.6673 17.1248 16.5041 17.5172 16.1777 17.8436C15.8513 18.17 15.459 18.3332 15.0007 18.3332H5.00065ZM10.834 7.49984V3.33317H5.00065V16.6665H15.0007V7.49984H10.834Z" fill="#85B0CB"/>
              </svg>
              <span>{link.text}</span>
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TopicsComponent;
