import React from 'react';
import useLoadData from '../hooks/useLoadData';
import { Footer } from '../@types/strapiDocs';

type FooterProps = {
  // Define your props here
};

const FooterComponent: React.FC<FooterProps> = () => {
  const { data } = useLoadData<Footer>('/footer', {
    populate: {
      Logo: true,
      FooterCol: {
        populate: {
          Collection: {
            populate: '*', 
          },
          Link: true,
          LinkLevel2: true,
          LinkLevel3: true,
          LinkLevel4: true,
        }
      },
      Privacy: true,
      Social: true
    },
  });


  const getIconSocial = (type: string) => {
    switch (type) {
      case 'facebook':
        return 'icon icon--facebook';
      case 'linkedin':
        return 'icon icon--linkedin';
      case 'blog':
        return 'icon icon--blog';
      case 'instagram':
        return 'icon icon--instagram';
      default:
        return '';
    }
  };

  // Footer options
  const logo = data?.attributes?.Logo?.data?.attributes?.url || '';
  const copyright = data?.attributes?.Copyright || '';
  const description = data?.attributes?.Description || '';
  const privacy = data?.attributes?.Privacy?.map((item) => ({
    externalLink: item.ExternalLink || '',
    name: item.Name || '',
    url: item.Url || '',
  })) || [];
  const social = data?.attributes?.Social?.map((item) => ({
    externalLink: item.ExternalLink || '',
    name: item.Name || '',
    url: item.Url || '',
    type: item.Type || '',
  })) || [];
  const col = data?.attributes?.FooterCol?.map((item) => ({
    link: item.Link || [],
    linkLevel2: item.LinkLevel2 || [],
    linkLevel3: item.LinkLevel3 || [],
    linkLevel4: item.LinkLevel4 || [],
    title: item.Title || '',
    size: item.Size?.trim() || '',
    enabled: item.Enabled || false,
    social: item.SocialOptions || false,
  })) || [];
  
  const getIconClass = (type: string) => {
    switch (type) {
      case 'support':
        return 'icon icon--support';
      case 'whatsapp':
        return 'icon icon--whatsapp';
      case 'email':
        return 'icon icon--email';
      case 'phone':
        return 'icon icon--phone';
      default:
        return '';
    }
  };
  
  return (
    <footer className="module">
      <div className="container">
        <div className="footer__row-top row">
          {col.map((link, index) => (
            link.enabled ? (  // Condicional dentro del JSX
              <div key={index} className={`${link.size === 'Large' ? 'col-lg-5' : 'col-lg-2 offset-lg-1'} footer__row-nav col-12`}>
                {link.title && <h3 className="text-white text-16 mb-3">{link.title}</h3>}

                <ul className={!link.social ? 'footer-nav' : 'footer-nav__services'}>
                  {link.link.length > 0 ? (
                    <ol>
                      {link.link.map((collectionItem, index) => (
                        <li key={index}>
                          <a
                            href={collectionItem?.Url}
                            target={collectionItem.ExternalLink ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {collectionItem.Type && <i className={getIconClass(collectionItem.Type)}></i>}
                            
                            {collectionItem?.Name}
                          </a>
                        </li>
                      ))}
                    </ol>
                   
                  ) : null}
                  {link.linkLevel2.length > 0 ? (
                    <ol>
                       {link.linkLevel2.map((collectionItem, index) => (
                        <li key={index}>
                          <a
                            href={collectionItem?.Url}
                            target={collectionItem.ExternalLink ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {collectionItem.Type && <i className={getIconClass(collectionItem.Type)}></i>}
                            
                            {collectionItem?.Name}
                          </a>
                        </li>
                      ))}
                    </ol>
                  ) : null}

                  {link.linkLevel3.length > 0 ? (
                    <ol>
                       {link.linkLevel3.map((collectionItem, index) => (
                        <li key={index}>
                          <a
                            href={collectionItem?.Url}
                            target={collectionItem.ExternalLink ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {collectionItem.Type && <i className={getIconClass(collectionItem.Type)}></i>}
                            
                            {collectionItem?.Name}
                          </a>
                        </li>
                      ))}
                    </ol>
                  ) : null}

                   {link.linkLevel4.length > 0 ? (
                    <ol>
                       {link.linkLevel4.map((collectionItem, index) => (
                        <li key={index}>
                          <a
                            href={collectionItem?.Url}
                            target={collectionItem.ExternalLink ? '_blank' : '_self'}
                            rel="noreferrer"
                          >
                            {collectionItem.Type && <i className={getIconClass(collectionItem.Type)}></i>}
                            
                            {collectionItem?.Name}
                          </a>
                        </li>
                      ))}
                    </ol>
                  ) : null}
                </ul>
              </div>
            ) : null // No renderiza nada si enabled es false
          ))}
        </div>
        <div className="footer__row-bottom row">
          <div className="footer__row-nav col-lg-3 col-12">
            {(logo || copyright || description) && <a href="/" className="footer__logo">
              <img src={logo} alt="Sitic Centro de Ayuda"/>
              {(copyright || description) &&  <span>
                {copyright}
                {description && <><br/><small>{description}</small></>}
              </span>}
             
            </a>}
          </div>
          <div className="footer__row-nav col-lg-9 col-12">
            <nav className="footer-bottom-nav">
              <ul>
                {privacy.map((link, index) => (
                  <li key={index}><a href={link.url} target={link.externalLink ? '_blank' : '_self'} rel="noreferrer">{link.name}</a></li>
                ))}
                {social.map((link, index) => (
                  <li key={index}>
                    <a href={link.url} target={link.externalLink ? '_blank' : '_self'} rel="noreferrer">
                      <i className={getIconSocial(link.type)}></i>
                      {link.name}
                      </a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterComponent;