import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './App.scss';
import ScrollToTop from './components/ScollToTop/scrollToTop.component';
import HomeScreen from './pages/home/home.screen';
import NotFoundScreen from './pages/notFound/notFound.screen';
import ModulesScreen from './pages/modules/modules.screen';
import SearchScreen from './pages/search/search.screen';
import ModuleScreen from './pages/modules/module.screen';
import ModuleDetailScreen from './pages/modules/moduleDetail.screen';
import ModuleDownloadsScreen from './pages/modules/moduleDownloads.screen';
import TypeScreen from './pages/type/type.screen';
import PrivacyScreen from './pages/privacy/privacy.screen';
import TermsScreen from './pages/privacy/terms.screen';
import LoginScreen from './pages/Auth/Login/login.screen';

function App() {
  return (
    <main className="app-main">
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<HomeScreen />} />
            <Route path="/modulos-sitic" element={<ModulesScreen />} />
            <Route path="/:collection" element={<ModuleScreen />} />
            <Route path="/:collection/:module" element={<ModuleDetailScreen />} />
            <Route path="/:collection/:download/:module" element={<ModuleDownloadsScreen />} />
            <Route path="/contenidos/:collection" element={<TypeScreen />} />
            <Route path="/resultado-de-busqueda" element={<SearchScreen />} />
            <Route path="/aviso-de-privacidad" element={<PrivacyScreen />} />
            <Route path="/terminos-y-condiciones" element={<TermsScreen />} />
            <Route path="/inicio-de-sesion" element={<LoginScreen />} />
            <Route path="*" element={<NotFoundScreen />} /> {/* Ruta 404 */}
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </main>
  );
}

export default App;
