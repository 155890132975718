import React, { useRef, useState, useEffect } from 'react';
import Overlay from 'react-bootstrap/Overlay';
import swal from 'sweetalert2';
import ModalShareComponent from '../Modal/modalShare.component';
import ModalErrorComponent from '../Modal/modalError.component';

interface Props {
  title?: string;
  authorName?: string;
  authorUrl?: string;
  date?: string;
  time?: string;
  tags?: string[];
  username?: string;
  module?: string
  url?: string;
}

const CentralContentComponent: React.FC<Props> = ({ title, authorUrl, authorName, date, time, tags, username, module, url }) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalError, setShowModalError] = useState(false);

  const shareTarget = useRef(null);
  const optionsTarget = useRef(null);
  const componentId = 'central-content'; // ID del div padre

  const copyLinkToClipboard = () => {
    const url = `${window.location.href.split('#')[0]}#${componentId}`;
    navigator.clipboard.writeText(url).then(() => {
      swal.fire({
        title: 'Link copiado',
        text: 'El link ha sido copiado al portapapeles',
        icon: 'success',
        confirmButtonText: 'Ok'
      });
    }, (err) => {
      console.error('Error al copiar el link: ', err);
    });
  };

  const toggleFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalErrorClose = () => {
    setShowModalError(false);
  };

  useEffect(() => {
    if (showShare) {
      setShowOptions(false);
    }
    if (showOptions) {
      setShowShare(false);
    }
  }, [showShare, showOptions]);

  return (
    <>
      <ModalShareComponent
        show={showModal}
        handleClose={handleModalClose}
        shareUrl={url}
      />
      <ModalErrorComponent
        show={showModalError}
        title={title}
        username={username}
        module={module}
        handleClose={handleModalErrorClose}
      />
      <section id="index" className="central-content">
        <div className="head-content">
          <div className="col-lg-8 col-12">
            {title && <h3 className="text-500 h4 text-blue700 col-lg-12 col-8" dangerouslySetInnerHTML={{__html: title}}/>}
            <div className="author">
              <figure className="author__image">
                <img src={authorUrl} alt={authorName} />
              </figure>
              <p className="content-data text-blue300 text-12">
                {authorName && (
                  <>
                    <span className="text-blue400">Por {authorName}</span>
                    {' · '}
                  </>
                )}
                {(date || time) && (
                  <span>
                    {date && <>Actualizado {date} {' · '}</>}
                    {time && <> {time} min de lectura</>}
                  </span>
                )}
                 {tags && tags.length > 0 && (
                  <span>
                    {tags.map((tag, index) => (
                      <span key={index} className="badge text-bg-light">
                        {tag}
                      </span>
                    ))}
                  </span>
                )}
              </p>
            </div>
          </div>
          <aside className="shared">
            <button
              type="button"
              className="liker__button-btn share"
              ref={shareTarget}
              onClick={() => setShowShare(!showShare)}
            >
              <i className="icon icon--share"></i>
            </button>
            <Overlay
              target={shareTarget.current}
              show={showShare}
              placement="bottom"
            >
              <div onMouseLeave={() => setShowShare(false)}>
                <div className="overlay-element">
                  <div>
                    <button
                      className="btn btn--tag"
                      onClick={copyLinkToClipboard}
                    >
                      <i className="icon icon--info"></i> Copiar link
                    </button>
                    <button
                      className="btn btn--tag"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="icon icon--share-small"></i> Compartir por correo
                    </button>
                  </div>
                </div>
              </div>
            </Overlay>
            
            <button
              type="button"
              className={`liker__button-btn favorites ${isFavorite ? 'active' : ''}`}
              onClick={toggleFavorite}
            >
              <i className="icon icon--favorite"></i>
            </button>
            
            <button
              type="button"
              className="liker__button-btn options"
              ref={optionsTarget}
              onClick={() => setShowOptions(!showOptions)}
            >
              <i className="icon icon--options"></i>
            </button>
            <Overlay
              target={optionsTarget.current}
              show={showOptions}
              placement="bottom"
            >
              <div onMouseLeave={() => setShowOptions(false)}>
                <div className="overlay-element">
                  <div>
                    <button
                      className="btn btn--tag"
                      onClick={() => setShowModalError(true)}
                    >
                      <i className="icon icon--info"></i> Reportar error
                    </button>
                  </div>
                </div>
              </div>
            </Overlay>
          </aside>
        </div>
      </section>
    </>
  );
};

export default CentralContentComponent;
