import React from 'react';

interface Props {
  title?: string;
  description?: string;
}

const CentralContentMainComponent: React.FC<Props> = ({title, description}) => {
  // Implement your component logic here

  return (
    <section className="central-content">
      <div className="head-content">
        {title && <div className="head-content__title"><h2 className="text-500 text-blue700 mb-0">{title}</h2></div>}
      </div>
      {description &&  <div className="central-content__into mb-4" dangerouslySetInnerHTML={{__html: description}}/>}
    
    </section>
  );
};

export default CentralContentMainComponent;