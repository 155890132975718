import React, { useRef, useState } from 'react';
import YouTube, { YouTubeProps, YouTubePlayer } from 'react-youtube';

interface VideoPlayerProps {
  videoUrl: string;
  tag?: boolean;
}

const VideoPlayer: React.FC<VideoPlayerProps> = ({ videoUrl, tag }) => {
  const playerRef = useRef<YouTubePlayer | null>(null);
  const [playerReady, setPlayerReady] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayButtonClick = () => {
    if (playerRef.current) {
      playerRef.current.playVideo();
      setIsPlaying(true);
    }
  };

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '640',
    playerVars: {
      autoplay: 0, // No autoplay on load
    },
  };

  const videoId = videoUrl.split('v=')[1]?.split('&')[0];

  const handlePlayerReady: YouTubeProps['onReady'] = (event) => {
    playerRef.current = event.target;
    setPlayerReady(true);
  };

  return (
   <>
     <div className="video-player">
      {!isPlaying && <figure className="video-player__cover"></figure>}
      <YouTube videoId={videoId} opts={opts} onReady={handlePlayerReady} />
      {!isPlaying && (
        <button
          onClick={handlePlayButtonClick}
          disabled={!playerReady}
          className="btn btn--video"
        >
          <span className="material-symbols-outlined icon">play_circle</span>
        </button>
      )}
     
    </div>
    {tag == true &&  <div className="video-tag">
      <p> ¿No carga el video? Consúltelo en <a href="#"><span>nuestro canal de Youtube</span> <span className="material-symbols-outlined icon">open_in_new</span></a></p>
    </div>}
   
   </>
  );
};

export default VideoPlayer;
